<template>
  <div>
    <div class="vx-row mb-12"></div>

    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead"> </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.creditNote"
              :class="highlight[indextr]"
            >
              <vs-td>
                <template>
                  <vx-tooltip text="View Credit Note">
                    <vs-button
                      color="primary"
                      type="line"
                      icon-pack="feather"
                      v-on:click="handleView(tr, indextr)"
                      icon="icon-eye"
                    />
                  </vx-tooltip>
                </template>
                <template v-if="tr.SalesReturnCode">
                  <vx-tooltip text="Print Credit Note">
                    <vs-button
                      color="primary"
                      type="line"
                      icon-pack="feather"
                      v-on:click="print(tr)"
                      icon="icon-printer"
                    />
                  </vx-tooltip>
                </template>
              </vs-td>
              <vs-td>
                Credit Note Code : {{ tr.Code }}<br />
                Debit Note Code :
                {{ getDebitNote(tr.DebitNoteID, tr.CnReferenceCode) }}
                <br />
                SOA Number : {{ responseData.debitNote[indextr].SoaNumber }}
                <br />
                <span v-if="tr.SalesReturnCode != ''">
                  Customer Return Code :
                  {{ tr.SalesReturnCode == "" ? "-" : tr.SalesReturnCode }} <br
                /></span>
                <span v-if="tr.SalesReturnCode != ''">
                  Customer Return Reference Code :
                  {{ responseData.salesReturn[indextr].SrReferenceCode }}<br
                /></span>
                Type :
                {{
                  tr.Sources == "Import Claim"
                    ? ""
                    : getSalesReturnType(tr.SalesReturnID)
                }}
                <template v-if="tr.SalesReturnCode == ''">
                  {{ generateType(tr.Type) }}
                </template>
              </vs-td>
              <vs-td>
                Code : {{ tr.CustomerCode }}<br />
                Name : {{ tr.CustomerName }}<br />
              </vs-td>
              <vs-td>
                {{ dateFormat(tr.PostingDate) }}
              </vs-td>
              <vs-td>
                <p>
                  Initial Invoice :
                  {{
                    initialInvoice[indextr].Code
                      ? initialInvoice[indextr].Code
                      : "-"
                  }}
                </p>
                <p>
                  Invoice :
                  {{
                    tr.InvoiceCreditNote != ""
                      ? tr.InvoiceCreditNote
                      : tr.InvoiceNumber != ""
                      ? tr.InvoiceNumber
                      : tr.InvoiceCode
                  }}
                </p>

                <p>
                  {{ tr.Type === "Manual" ? "Reference : " : "Note : " }}
                  {{ tr.Note ? tr.Note : "-" }}
                </p>
                <p>
                  Total Value CN :
                  {{
                    parseFloat(tr.TotalValue)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                </p>
                <p>
                  Remaining Value :
                  {{
                    tr.Remaining.toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                </p>
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
        <vs-popup
          fullscreen
          title="Print"
          :active.sync="showPdf"
          :button-close-hidden="false"
        >
          <div class="flex flex-col gap-6 h-full">
            <iframe :src="pdfUrl" class="w-full" style="min-height: 80vh" />
            <div class="flex gap-3 w-full justify-end">
              <vs-button color="danger" @click="handleClosePopUp"
                >Cancel</vs-button
              >
              <vs-Button @click="handlePrintPDFPreview">Print</vs-Button>
            </div>
          </div>
        </vs-popup>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <template
              v-if="
                selectedData.Type == 'Manual Claim' ||
                selectedData.Type == 'Manual'
              "
            >
              <view-manual @close="closeDetail" :selected="selectedData" />
            </template>
            <template v-else>
              <form-view @close="closeDetail" :selected="selectedData" />
            </template>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import formView from "./form_view.vue";
import viewManual from "./view_manual.vue";
import { dataTableCreditNote } from "../../../../services/api/credit_note";
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import angkaTerbilang from "@develoka/angka-terbilang-js";
export default {
  props: {
    selected: Object,
    option: Object,
    isReload: Boolean,
  },
  components: {
    "form-view": formView,
    "view-manual": viewManual,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "credit_notes.id",
      },
      // note:"Full Return",

      header: [
        {
          text: "Action",
          sortable: false,
        },
        {
          text: "Code",
          value: "credit_notes.code",
        },
        {
          text: "Customer",
          value: "credit_notes.customer_name",
          // width: '5%'
        },
        {
          text: "Posting Date",
          sortable: false,
          // width: '5%'
        },
        {
          text: "Info",
          sortable: false,
          // width: '5%'
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      usedValue: 0,
      validate: [],
      validateAll: false,
      salesReturn: [],
      salesReturnType: [],
      initialInvoice: [],
      index1: 0,
      highlight: [],
      selectedCreditNote: {},
      showPdf: false,
      pdfUrl: "",
      pdfProxy: false,
    };
  },
  computed: {},
  watch: {
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.reloadData(this.params);
    },
  },
  mounted() {},
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY");
      }
    },
    addValidate(ID) {
      console.log(this.validate.length);
      if (this.validate.includes(ID)) {
        this.validate.splice(this.validate.indexOf(ID), 1);
      } else {
        this.validate.push(ID);
      }
    },
    getSalesReturnType(salesReturnID) {
      try {
        const salesReturn = this.salesReturn.filter((v) => {
          return v.ID == salesReturnID;
        })[0];
        console.log(salesReturn, salesReturnID);
        return this.salesReturnType.filter((v) => {
          return v.ID == salesReturn.SalesReturnTypeID;
        })[0].Name;
      } catch (err) {
        return "";
      }
    },
    addAllValidate() {
      console.log(this.data, this.validateAll);
      if (this.validateAll == false) {
        for (var i in this.data) {
          if (!this.validate.includes(this.data[i].ID)) {
            this.validate.push(this.data[i].ID);
          }
        }
      } else {
        this.validate = [];
      }
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    handlePrint(tr) {
      window.open(`/finance/credit-note/print-tax-return/${tr.ID}`);
    },
    handleClosePopUp() {
      this.pdfProxy = false;
      this.showPdf = false;
      this.selectedCreditNote = {};
      this.pdfUrl = "";
    },
    handleShowPopUp() {
      this.showPdf = true;
    },
    handlePrintPDFPreview() {
      const creditNote = this.selectedCreditNote;
      const fileName = `credit_note-${creditNote.Code}.pdf`;
      this.pdfProxy.save(fileName);
      this.handleClosePopUp();
      this.updatePrintCount(creditNote.ID);
    },
    updatePrintCount(id) {
      this.$vs.loading();
      this.$http
        .patch(`api/v1/credit-note/print-count/${id}`)
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Print",
              text: "Print Success",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Print",
              text: "Print Failed",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
          this.reloadData(this.params);
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
    async readCreditNote(id) {
      try {
        const resp = await this.$http.get(`api/v1/credit-note/read/${id}`);
        if (resp.code < 299) {
          return resp;
        } else {
          throw new Error(resp.message);
        }
      } catch (e) {
        throw new Error(e.message);
      }
    },
    setPos(pageWidth, pos, offset) {
      if (pos >= pageWidth) {
        return pageWidth;
      } else {
        if (pos <= 0) {
          return offset;
        } else {
          return (pos += offset);
        }
      }
    },
    calculatePaymentTerm(date, due_date) {
      const dueDate = moment(due_date);
      const invoiceDate = moment(date);
      const diff = dueDate.diff(invoiceDate, "days");
      return diff;
    },
    generateCreditNoteInformation(
      pdf,
      creditNote,
      salesOrder,
      salesMan,
      customer,
      cursorY,
      pageWidth
    ) {
      const orderDate = moment(salesOrder.Date).format("DD.MM.YYYY");
      const dueDate = moment(salesOrder.DueDate).format("DD.MM.YYYY");
      const soDate = moment(salesOrder.Date).format("DD.MM.YYYY");
      const invalidDate = "01.01.0001";

      const header = [
        { header: "", dataKey: "title" },
        { header: "", dataKey: "delimiter" },
        { header: "", dataKey: "value" },
      ];
      const body = [
        { title: "Print", delimiter: ":", value: creditNote.PrintCount + 1 },
        { title: "Credit Note No.", delimiter: ":", value: creditNote.Code },
        {
          title: "Credit Note Date",
          delimiter: ":",
          value: moment(creditNote.Date).format("DD.MM.YYYY"),
        },
        {
          title: "Order No.",
          delimiter: ":",
          value: salesOrder.Code ? salesOrder.Code : "-",
        },
        {
          title: "Order Date",
          delimiter: ":",
          value: orderDate === invalidDate ? "-" : orderDate,
        },
        {
          title: "Customer No.",
          delimiter: ":",
          value: creditNote.CustomerCode,
        },
        {
          title: "Sales Office",
          delimiter: ":",
          value: this.getTerritoryCode(creditNote.TerritoryID),
        },
        {
          title: "Condition Delivery",
          delimiter: ":",
          value: salesOrder.DeliveryTypeName
            ? salesOrder.DeliveryTypeName
            : "-",
        },
        {
          title: "Term of Payment",
          delimiter: ":",
          value:
            soDate != invalidDate && dueDate != invalidDate
              ? `Due within ${this.calculatePaymentTerm(
                  salesOrder.Date,
                  salesOrder.DueDate
                )} days`
              : "-",
        },
        {
          title: "Due Date",
          delimiter: ":",
          value: dueDate === invalidDate ? "-" : dueDate,
        },
        {
          title: "Tax Status/Currency",
          delimiter: ":",
          value: `${customer.is_pkp === "0" ? "Non PKP" : "PKP"} / IDR`,
        },
        {
          title: "Sales Code/Salesman",
          delimiter: ":",
          value: `${salesMan.IDNumber ? salesMan.IDNumber : "-"}/${
            salesMan.Name ? salesMan.Name : "-"
          }`,
        },
      ];
      autoTable(pdf, {
        startY: cursorY,
        columns: header,
        body: body,
        showHead: "never",
        styles: {
          lineWidth: 0,
          fontSize: 11,
          font: "courier",
          valign: "middle",
        },
        columnStyles: {
          title: { cellWidth: 5, cellPadding: 0 },
          delimiter: { cellWidth: 0.5, cellPadding: 0 },
          value: { cellWidth: 5, cellPadding: 0 },
        },
        theme: "plain",
        margin: {
          left: this.setPos(pageWidth, pageWidth / 2 + 1, 1),
          right: 1,
        },
        didParseCell(data) {
          const columnIndex = data.column.index;
          if (columnIndex === 2) {
            data.cell.styles.halign = "left";
          }
        },
      });

      return pdf.lastAutoTable.finalY;
    },
    generateCreditNoteLine(pdf, resp, cursorY, pageWidth, pageHeight, padding) {
      const creditNote = resp.data.creditNote;
      const creditNoteLines = resp.data.creditNoteLine;
      let prevPage = 1;
      let isDrawLine = false;
      let isDraw = false;
      const header = [
        { header: "Product", dataKey: "product" },
        { header: "Quantity", dataKey: "qty" },
        { header: "UoM", dataKey: "uom" },
        { header: "Price Value", dataKey: "price_value" },
        { header: "Net Sales", dataKey: "net_sales" },
      ];
      let body = creditNoteLines.map((line) => {
        const dppLine = line.Total - line.TaxAmount;
        const price = dppLine / line.Qty;
        return {
          product: line.ItemName,
          qty: line.Qty,
          uom: line.ItemUnit,
          price_value: `${this.priceFormat(price)} / 1${
            line.ItemUnit ? line.ItemUnit : ""
          }`,
          net_sales: this.priceFormat(dppLine),
        };
      });

      const dpp = creditNote.TotalValue - creditNote.TaxValue;
      const total = [
        //       GROSS TOTAL 4.382.478
        // DISCOUNT 100.081-
        // SUBTOTAL 4.282.397
        // DP 0
        // DPP 4.282.397
        // PPN 11% 471.063
        // TOTAL 4.753.460
        {
          product: "",
          qty: "",
          uom: "DPP",
          price_value: "",
          net_sales: this.priceFormat(dpp),
        },
        {
          product: "",
          qty: "",
          uom: "PPN 11%",
          price_value: "",
          net_sales: this.priceFormat(creditNote.TaxValue),
        },
        {
          product: "",
          qty: "",
          uom: "TOTAL",
          price_value: "",
          net_sales: this.priceFormat(creditNote.TotalValue),
        },
      ];

      body = body.concat(total);

      autoTable(pdf, {
        startY: cursorY,
        columns: header,
        body: body,
        styles: {
          lineWidth: 0,
          fontSize: 11,
          font: "courier",
          valign: "middle",
        },
        columnStyles: {
          product: { cellWidth: 10, cellPadding: 0 },
          qty: { cellPadding: 0 },
          uom: { cellPadding: 0 },
          price_value: { cellPadding: 0 },
          net_sales: { cellPadding: 0 },
        },
        margin: {
          top: this.setPos(pageHeight, cursorY, padding),
          bottom: this.setPos(pageHeight, 1.5, padding),
        },
        theme: "plain",
        // margin: { left: this.setPos(pageWidth, pageWidth - 10, 1), right: 1 },
        didParseCell(data) {
          const columnIndex = data.column.index;
          const rowIndex = data.row.index;
          if (columnIndex === 1 || columnIndex === 4) {
            data.cell.styles.halign = "right";
            data.cell.styles.cellPadding = 0.1;
          }

          if (rowIndex === body.length - 7) {
            data.cell.styles.minCellHeight = 0.5;
            data.cell.styles.valign = "bottom";
          }

          if (rowIndex >= body.length - 7) {
            data.cell.styles.fontStyle = "bold";
          }
        },
        didDrawPage: (data) => {
          if (prevPage !== data.pageCount) {
            isDrawLine = true;
            prevPage = data.pageCount;
          }
        },
        didDrawCell: (data) => {
          // pdf.text(
          //   "Hello",
          //   this.setPos(pageWidth, 0.5, padding),
          //   this.setPos(pageHeight, cursorY, padding)
          // );
          const rowIndex = data.row.index;
          const cellX = data.cell.x;
          const cellY = data.cell.y;
          if (rowIndex === 0 || rowIndex === body.length - 3) {
            pdf.setLineWidth(0.01);
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineDashPattern([0.2, 0.2], 0);
            pdf.line(cellX, cellY, cellX + data.cell.width, cellY);
            if (isDrawLine) {
              isDrawLine = false;
              isDraw = true;
            }
          }
        },
      });

      return pdf.lastAutoTable.finalY;
    },
    generateHeader(pdf, resp, cursorY, pageWidth, pageHeight, padding) {
      const creditNote = resp.data.creditNote;
      const customer = resp.data.customer;
      const salesOrder = resp.data.salesOrder;
      const salesMan = resp.data.salesMan;
      const branch = resp.data.branch;

      pdf.text(
        "PT. SINARMAS DISTRIBUSI NUSANTARA",
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      cursorY += 0.45;
      // pdf.text(
      //   "Cab. Jakarta Selatan",
      //   this.setPos(pageWidth, 0.5, padding),
      //   this.setPos(pageHeight, cursorY, padding)
      // );
      // cursorY += 0.45;

      const address = `${branch.Address}, ${branch.SubDistrict}, ${branch.District}, ${branch.City}`;
      const addrLine = pdf.splitTextToSize(
        address,
        this.setPos(pageWidth, pageWidth / 2 - 1, padding)
      );
      const addrHeight = pdf.getTextDimensions(address).h;

      pdf.text(
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding),
        addrLine
      );

      cursorY += 1 + addrHeight * addrLine.length;

      // pdf.text(
      //   "021-87727150",
      //   this.setPos(pageWidth, 0.5, padding),
      //   this.setPos(pageHeight, cursorY, padding)
      // );

      // cursorY += 0.8;

      pdf.text(
        "NPWP SDN: 01.604.506.4-007.000",
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      cursorY += 0.8;

      const supplier = customer.name;
      const supplierAddr = `${customer.address.toUpperCase()}, ${customer.sub_district.toUpperCase()}, ${customer.district.toUpperCase()}, ${customer.city.toUpperCase()}`;
      const supplierAddrLine = pdf.splitTextToSize(
        supplierAddr,
        this.setPos(pageWidth, pageWidth / 2 - 1, padding)
      );

      pdf.text(
        "Payer :",
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      cursorY += 0.45;

      pdf.text(
        supplier,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      cursorY += 0.45;

      pdf.text(
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding),
        supplierAddrLine
      );

      const custAddrHeight = pdf.getTextDimensions(supplierAddr).h;
      cursorY += 0.5 + custAddrHeight * supplierAddrLine.length;

      pdf.text(
        `NPWP Customer : ${customer.npwp === "" ? "-" : customer.npwp}`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      cursorY = 0;

      // const faktur = "FAKTUR";
      // const billing = "(BILLING)";

      // const billingWidth = pdf.getTextDimensions(billing).w;
      // const fakturWidth = pdf.getTextDimensions(faktur).w;
      // const xPos =
      //   pageWidth - pageWidth / 4 - 0.5 + (billingWidth - fakturWidth) / 2;
      // pdf.setFontSize(12);
      // pdf.setFont("courier", "bold");

      // pdf.text(
      //   faktur,
      //   this.setPos(pageWidth, xPos, padding),
      //   this.setPos(pageHeight, cursorY, padding)
      // );

      // cursorY += 0.4;

      pdf.text(
        "CREDIT NOTE",
        this.setPos(pageWidth, pageWidth - pageWidth / 4 - 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );
      cursorY += 0.3;

      this.dashedLine(
        pdf,
        pageWidth / 2 + 1,
        cursorY,
        pageWidth + 0.5,
        cursorY,
        pageWidth + 0.5,
        pageHeight,
        padding
      );

      cursorY += 1.3;

      cursorY = this.generateCreditNoteInformation(
        pdf,
        creditNote,
        salesOrder,
        salesMan,
        customer,
        cursorY,
        pageWidth
      );

      return cursorY;
    },
    dashedLine(pdf, x1, y1, x2, y2, pageWidth, pageHeight, padding) {
      pdf.setLineWidth(0.01);
      pdf.setDrawColor(0, 0, 0);
      pdf.setLineDashPattern([0.2, 0.2], 0);
      pdf.line(
        this.setPos(pageWidth, x1, padding),
        this.setPos(pageHeight, y1, padding),
        this.setPos(pageWidth, x2, padding),
        this.setPos(pageHeight, y2, padding)
      );
    },
    getTerritoryCode(id) {
      if (id === 0) {
        return "";
      }
      const territories = JSON.parse(
        JSON.stringify(this.$store.state.operatingUnit.filter.territory)
      );

      const territory = territories.find(
        (territory) => territory.TerritoryID === id
      );

      return territory.TerritoryCode ? territory.TerritoryCode : "";
    },
    generateOtherInfo(pdf, resp, cursorY, pageWidth, pageHeight, padding) {
      const creditNote = resp.data.creditNote;
      // const payment = resp.data.payment[0];
      let cursor = cursorY;
      const salesOrder = resp.data.salesOrder;
      const saying = this.generateNumberSaying(creditNote.TotalValue);
      const sayingLines = pdf.splitTextToSize(
        saying,
        this.setPos(pageWidth, pageWidth, padding)
      );

      this.dashedLine(
        pdf,
        0.5,
        cursor,
        pageWidth + 0.5,
        cursor,
        pageWidth + 0.5,
        pageHeight,
        padding
      );

      cursor += 0.5;
      pdf.text(
        "SAY :",
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );
      pdf.text(
        this.setPos(pageWidth, 2.0, padding),
        this.setPos(pageHeight, cursor, padding),
        sayingLines
      );

      const sayingHeight = pdf.getTextDimensions(saying).h * sayingLines.length;

      cursor += sayingHeight;

      this.dashedLine(
        pdf,
        0.5,
        cursor,
        pageWidth + 0.5,
        cursor,
        pageWidth + 0.5,
        pageHeight,
        padding
      );

      cursor += 0.5;

      pdf.text(
        `Sales Order : ${salesOrder.Code ? salesOrder.Code : "-"}`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor += 0.5;

      pdf.text(
        `Customer PO. No. : ${
          creditNote.SalesReturnCode || creditNote.SalesReturnCode === ""
            ? creditNote.SalesReturnCode
            : "-"
        }`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor += 0.5;

      pdf.text(
        `Message : ${creditNote.Note}`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor += 0.5;

      this.dashedLine(
        pdf,
        0.5,
        cursor,
        pageWidth + 0.5,
        cursor,
        pageWidth + 0.5,
        pageHeight,
        padding
      );

      cursor += 1;

      if (cursor > pageHeight - 2.5) {
        pdf.addPage();
        cursor = 1;
      }

      pdf.text(
        `Notes:`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor += 0.5;

      if (cursor >= pageHeight - 1.8) {
        pdf.addPage();
        cursor = 1;
      }

      pdf.setFont("courier", "normal");

      pdf.text(
        `This is a computer generated document and no signature is required`,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor = this.setPos(pageHeight, pageHeight - 1, padding);

      pdf.setFont("courier", "bold");
      pdf.text(
        `Bank Account : `,
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor -= 3;

      const dkiJakarta = `DKI JAKARTA`;
      const dkiLength = pdf.getTextDimensions(dkiJakarta).w;
      const seo = "SE & O";
      const seoLength = pdf.getTextDimensions(seo).w;
      const sign = "(        )";
      const signLength = pdf.getTextDimensions(sign).w;
      const posX = (dkiLength - seoLength) / 2;
      const signPosx = (dkiLength - signLength) / 2;
      const baseX = pageWidth - pageWidth / 4;
      pdf.text(
        dkiJakarta,
        this.setPos(pageWidth, baseX, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor += 0.5;

      pdf.text(
        seo,
        this.setPos(pageWidth, baseX + posX, padding),
        this.setPos(pageHeight, cursor, padding)
      );

      cursor += 1;

      pdf.text(
        sign,
        this.setPos(pageWidth, baseX + signPosx, padding),
        this.setPos(pageHeight, cursor, padding)
      );
    },

    async print(tr) {
      try {
        this.$vs.loading();
        this.selectedCreditNote = tr;
        const resp = await this.readCreditNote(tr.ID);
        console.log(resp, "resp");
        const padding = 1;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });

        let cursorY = 0;

        pdf.setFontSize(11);
        pdf.setFont("courier", "bold");

        cursorY += 8;

        cursorY = this.generateCreditNoteLine(
          pdf,
          resp,
          cursorY,
          pageWidth,
          pageHeight,
          padding
        );

        cursorY -= 1;

        this.generateOtherInfo(
          pdf,
          resp,
          cursorY,
          pageWidth,
          pageHeight,
          padding
        );

        const pageCount = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.text(
            `Page ${i} of ${pageCount}`,
            this.setPos(pageWidth, pageWidth - 3, padding),
            this.setPos(pageHeight, pageHeight - 0.5, padding)
          );

          this.generateHeader(pdf, resp, 0, pageWidth, pageHeight, padding);
        }
        const url = pdf.output("dataurlstring");
        this.pdfUrl = url + "#toolbar=0";
        this.pdfProxy = pdf;
        this.handleShowPopUp();
        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
      }
    },
    generateNumberSaying(value) {
      return angkaTerbilang(value).toUpperCase();
    },
    handleView(selected, indextr) {
      if (selected.Type === "Manual") {
        this.$router.push({
          name: "view-credit-note-manual",
          params: { ID: btoa(selected.ID) },
        });
      } else {
        this.highlight[this.index1] = "";
        this.highlight[indextr] = "highlight";
        this.index1 = indextr;
        this.selectedData = selected;
        this.detail = true;
      }
      // this.$vs.loading();
      //   this.$vs.loading.close();
      // const creditNote = dataCreditNote(selected)
      // creditNote.then(r => {
      //   console.log(r)

      // })
    },
    handleDelete(selected) {
      console.log(selected);
    },
    reloadData(params) {
      // console.log("params", params);
      // this.params = params;
      // this.params.status = 4;
      // // this.params.note = this.note
      // this.params.applied = 0;

      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      this.params = {
        ...params,
        territory_ids,
        status: 4,
        applied: 0,
        type_not_in: ["Temporary"],
      };
      this.$vs.loading();

      const salesReturn = dataTableCreditNote(this.params);

      salesReturn.then((r) => {
        console.log(">>>>>>>>>>>", r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.creditNote;
          this.responseData = r.data;
          this.responseData.length = r.data.length;
          this.responseData.recordsTotal = r.data.recordsTotal;
          this.salesReturn = r.data.salesReturn;
          this.salesReturnType = r.data.salesReturnType;
          this.initialInvoice = r.data.initialInvoices;
          //   this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    getDebitNote(id, ref) {
      let dn = this.responseData.debitNote.find((v) => v.ID === id);
      if (dn && Object.keys(dn).length > 0 && dn.ID !== 0) {
        return dn.Code ? dn.Code : "-";
      }

      if (ref === "") {
        return "-";
      }

      dn = this.responseData.debitNote.find((v) => v.DnReferenceCode === ref);
      if (dn && Object.keys(dn).length > 0 && dn.ID !== 0) {
        return dn.Code ? dn.Code : "-";
      }

      return "-";
    },

    generateType(type) {
      if (type === "Manual") {
        return "Claim Mix";
      } else {
        return type;
      }
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}

.highlight > td {
  background-color: #d0cdf0;
}
</style>
